module.exports = [{
      plugin: require('../plugins/gatsby-plugin-primary-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-root-and-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true},
    },{
      plugin: require('../plugins/gatsby-plugin-cookie-information/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"da"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gammeldansk","short_name":"Gammeldansk","start_url":"/","background_color":"#f3f1eb","theme_color":"#ffe190","icon":"src/images/gammeldansk/favicon.svg","include_favicon":false,"display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"13ba84ab8102d2acaa8e595dba1cee9f"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
